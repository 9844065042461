body{
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
}

.navbar-inverse{
    background-color: #689f38 !important;
    background-image: none !important;
    border: 0px;
    border-radius: 0px;
}

.navbar-nav > .active > a, .navbar-nav > .open > a{
    background-color: #33691e !important;
    background-image: none !important;
    color: #00b0ff !important;
}

.navbar-nav > li > a{
    color: #ffffff !important;
}

.navbar-brand{
    color: #ffffff !important;
}

/*other*/

@media (min-width: 768px){
    .modal-dialog {
        margin-left: 50%;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 50%;
        height: 100%;
    }
    .modal-content {
        height: 100%;
        overflow-y: scroll;
    }
}

.modal-dialog{
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-content{
    height: 100%;
    overflow-y: scroll;
}
